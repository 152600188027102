var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('form',{staticClass:"login",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitScroll.apply(null, arguments)}}},[_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":119,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/customer"}},[_c('icon',{attrs:{"name":"x"}})],1),(_vm.isNew)?_c('h2',{staticClass:"title"},[_vm._v("Nowy klient")]):_c('h2',{staticClass:"title"},[_vm._v("Edytuj klienta")])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-primary"},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Zapisz")])],1)])])])],1),_c('div',{class:errors['firstname'] && errors['firstname'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v("Imię")]),(errors['firstname'] && errors['firstname'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["firstname"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"firstname"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.firstname),expression:"customer.firstname"}],staticClass:"input is-medium",attrs:{"name":"firstname"},domProps:{"value":(_vm.customer.firstname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "firstname", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['lastname'] && errors['lastname'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v("Nazwisko")]),(errors['lastname'] && errors['lastname'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["lastname"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"lastname"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.lastname),expression:"customer.lastname"}],staticClass:"input is-medium",attrs:{"name":"lastname"},domProps:{"value":(_vm.customer.lastname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "lastname", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['email'] && errors['email'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_email"}},[_vm._v("Email")]),(errors['email'] && errors['email'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["email"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"email"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.email),expression:"customer.email"}],staticClass:"input is-medium",attrs:{"name":"email"},domProps:{"value":(_vm.customer.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "email", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['phone'] && errors['phone'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_phone"}},[_vm._v("Telefon")]),(errors['phone'] && errors['phone'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["phone"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"phone"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.phone),expression:"customer.phone"}],staticClass:"input is-medium",attrs:{"name":"phone"},domProps:{"value":(_vm.customer.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "phone", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['nip'] && errors['nip'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_nip"}},[_vm._v("NIP")]),_c('p',[_vm._v("Aby pobrać dane firmy z bazy GUS")]),(errors['nip'] && errors['nip'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["nip"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-6"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"nip"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.nip),expression:"customer.nip"}],staticClass:"input is-medium",attrs:{"id":"f_company_nip","type":"text","placeholder":""},domProps:{"value":(_vm.customer.nip)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "nip", $event.target.value)}}})])],1)])]),_c('div',{staticClass:"column ym-column is-2"},[_c('a',{class:_vm.customer.nip
                ? _vm.isNIPloading
                  ? 'button is-fullwidth is-medium is-disabled'
                  : 'button is-fullwidth is-medium is-light'
                : 'button is-fullwidth is-medium is-disabled',on:{"click":function($event){$event.preventDefault();return _vm.NIPcheck.apply(null, arguments)}}},[(!_vm.isNIPloading)?_c('span',[_vm._v("Pobierz dane")]):_vm._e(),(_vm.isNIPloading)?_c('span',[_c('icon',{attrs:{"name":"loader"}})],1):_c('icon',{attrs:{"name":"download-cloud"}})],1)])]),_c('div',{class:errors['name'] && errors['name'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v("Pełna nazwa firmy")]),(errors['name'] && errors['name'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["name"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"name"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.name),expression:"customer.name"}],staticClass:"input is-medium",attrs:{"name":"name"},domProps:{"value":(_vm.customer.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "name", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['shortname'] && errors['shortname'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v("Skrócona nazwa firmy")]),(errors['shortname'] && errors['shortname'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["shortname"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"shortname"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.shortname),expression:"customer.shortname"}],staticClass:"input is-medium",attrs:{"name":"shortname"},domProps:{"value":(_vm.customer.shortname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "shortname", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['address'] && errors['address'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_street"}},[_vm._v("Ulica")]),_c('p',[_vm._v("Z numerem lokalu")]),(errors['address'] && errors['address'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["address"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"address"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.address),expression:"customer.address"}],staticClass:"input is-medium",attrs:{"name":"address"},domProps:{"value":(_vm.customer.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "address", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['postcode'] && errors['postcode'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_postcode"}},[_vm._v("Kod pocztowy")]),(errors['postcode'] && errors['postcode'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["postcode"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"postcode"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.postcode),expression:"customer.postcode"}],staticClass:"input is-medium",attrs:{"name":"postcode"},domProps:{"value":(_vm.customer.postcode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "postcode", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['city'] && errors['city'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_city"}},[_vm._v("Miejscowość")]),(errors['city'] && errors['city'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["city"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"city"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.city),expression:"customer.city"}],staticClass:"input is-medium",attrs:{"name":"city"},domProps:{"value":(_vm.customer.city)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.customer, "city", $event.target.value)}}})])],1)])])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }